export default function mapLayers (instance) {
  return {

    /**
     * Get list of layers based on the selected in gear settings REALM
     * @returns {Promise<*>}     */
    getCustomMapLayers: async (data) => {
      const resource = '/map-layers'
      const response = await instance.get(resource, data)
      return response
    },
    /**
 * Returns token for displaying secret map layer
 * @param map_layer_id
 * @returns {Promise<{"access_token": "string"}>}     */
    getCustomLayerToken: async (mapLayerId) => {
      const resource = '/map-layers/exchange-secret'
      const response = await instance.get(resource, {
        params: {
          map_layer_id: mapLayerId
        }
      })
      return response
    }
  }
}
