import EmergencyCallDataParser from '@/modules/api/csp/parsers/emergency-call-data.parser'
import adrXmlParser from '@/modules/api/csp/parsers/adr/adr-xml.parser'
import store from '@/store'
/*
 * TODO: After updating call history remove unused functions:
 * getLocations
 * getAdr
 * and all related variables
 */
export default class CallHistoryTransformer {
  event;
  reason;
  saveCall = false;
  dataObj = {}
  locationObj = {}
  adrObj = {}
  callBackNumber = {}
  realm = ''
  constructor (event) {
    this.event = event
    this.getReason()
  }

  async collectData () {
    if (this.saveCall) {
      this.setRequestData()
      this.getLocations()
      await this.getAdr()
      return { ...this.dataObj, ...this.locationObj, ...this.adrObj, ...this.callBackNumber }
    }
  }

  setRealm (realm) {
    this.realm = realm

    return this
  }

  getReason () {
    this.reason = this.event.eventData.message?.headers?.Reason?.[0].raw.match(/text="(.*?)"/)
    if (!this.reason || (this.reason[1] && this.reason[1] !== 'Call completed elsewhere')) {
      this.saveCall = true
    }
  }

  setRequestData () {
    this.dataObj = {
      realm: this.realm,
      call_id: this.event.session?._request?.call_id,
      hangup_cause: this.reason?.[1] || null,
      firestore_pk: this.getPrimaryKeyForFireStore(),
      call_direction: this.event.session?.direction,
      invite_message: this.event.session?._request?.toString(),
      resolution_action: 'unresolved',
      start_time: this.event.session?.start_time || new Date().toISOString()
    }
  }

  getPrimaryKeyForFireStore () {
    let headers = this.event.session?._request?.headers

    if (!headers) return

    headers = headers['Call-Info']

    if (!headers) return

    let fireSasePrimaryKey = null

    headers.forEach(header => {
      const incidentid = header?.raw?.match(/incidentid:(.*):/i)

      if (incidentid) fireSasePrimaryKey = incidentid[1]
    })

    return fireSasePrimaryKey
  }

  getLocations () {
    if (this.event.session?._request?.headers?.Geolocation) {
      this.locationObj = new EmergencyCallDataParser().parseGeoLocation(this.event.session._request.headers.Geolocation)
    }
  }

  async getAdr () {
    if (this.event.session?._request?.headers?.['Call-Info']) {
      this.adrObj = new EmergencyCallDataParser().parseAdr(this.event.session._request.headers['Call-Info'])
      await this.getCallBeckNumber(this.adrObj?.subscriber_info_url)
    } else {
      await this.getCallBeckNumber(null)
    }
  }

  async getCallBeckNumber (subscriberInfoUrl) {
    let phoneNumber = ''
    if (subscriberInfoUrl) {
      await adrXmlParser().parse(subscriberInfoUrl, 'SubscriberInfo').then(data => {
        const callBackNumber = data?.SubscriberData?.vcard?.tel?.uri?.replace('tel:', '')
        if (callBackNumber) {
          this.callBackNumber.call_from = this.removePrefix(callBackNumber)
        } else {
          phoneNumber = (this.event.session?.direction === 'incoming') ? this.event.session?._request?.from?._uri?._user : this.event.session?._request?.to?._uri?._user
          this.callBackNumber.call_from = this.removePrefix(phoneNumber)
        }
      })
    } else {
      phoneNumber = (this.event.session?.direction === 'incoming') ? this.event.session?._request?.from?._uri?._user : this.event.session?._request?.to?._uri?._user
      this.callBackNumber.call_from = this.removePrefix(phoneNumber)
    }
  }

  removePrefix (number) {
    if (this.event.session?.direction === 'outgoing' && store.getters['settings/getGlobalSetting']('can_make_outgoing_conference').value.active) {
      const prefix = store.getters['settings/getGlobalSetting']('outgoing_conference_prefix')?.value?.text || '800'
      if (number.startsWith(prefix)) {
        return number.slice(prefix.length)
      }
    } else return number
  }
}
