import helpers from '@/plugins/i3-logger/helpers/helper'
import localStorageService from '@/util/local-storage-service'
import store from '@/store'

export default class LogEventSchema {
  // Page 631 of 660
  /* LogEvent:
      type: object
      required:
        - logEventType
        - timestamp
        - agencyId
        - elementId
      properties:
        clientAssignedIdentifier:
            type: string
        logEventType:
          $ref: '#/components/schemas/LogEventType'
        timestamp:
          type: string
        format: date-time
        example: '2020-03-10T11:00:01-05:00'
        elementId:
          type: string
        agencyId:
          type: string
        agencyAgentId:
          type: string
        agencyPositionId:
          type: string
        callId:
          type: string
        incidentId:
          type: string
        callIdSip:
          type: string
        ipAddressPort:
          type: string
        extension:
          type: object
      discriminator:
        propertyName: logEventType */
  logEvent (session, logEventName, subEventName = null) {
    return {
      clientAssignedIdentifier: store.getters['settings/sipDefaultAddressGetter'].sip_address,
      logEventType: logEventName,
      timestamp: helpers().getCorrectTime(session, logEventName),
      elementId: window.location.hostname,
      agencyId: store.getters['settings/sipDefaultAddressGetter'].realm,
      agencyAgentId: store.getters['global/authUserGetter'].email.split('@')[0],
      agencyPositionId: localStorageService.getPositionNumber(),
      callId: helpers().getNenaUid(session?._request?.headers?.['Call-Info'], 'callid'),
      incidentId: helpers().getNenaUid(session?._request?.headers?.['Call-Info'], 'incidentid'),
      callIdSip: session?._request?.call_id,
      ipAddressPort: helpers().getIpAddressAndPort(session, logEventName, subEventName) || store.getters['settings/sipDefaultAddressGetter'].realm
    }
  }
}
